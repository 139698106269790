/**
 * Site header
 */
.site-header {
  padding: 10px 0px;
  border-bottom: 1px solid $grey-color-light;
  margin-bottom: 30px;
}


/**
 * Site footer
 */
.site-footer {
  padding: 10px 0px;
  border-top: 1px solid $grey-color-light;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &__col {
    a {
      align-items: center;
      justify-content: center;
      display: flex;
    }
  }
}

/**
 * Page content
 */
.page-content {
  flex: 1;
}

.page-heading {
  @include relative-font-size(2);
}

.post-list-heading {
  @include relative-font-size(1.75);
}

.post-list {
  margin-left: 0;
  list-style: none;

  > li {
    margin-bottom: $spacing-unit;
  }
}

.post-meta {
  font-size: $small-font-size;
  color: $grey-color;
}

.post-link {
  display: block;
  @include relative-font-size(1.5);
}



/**
 * Posts
 */
.post-header {
  margin-bottom: 15px;
}

.post-title {
  @include relative-font-size(2.625);
  letter-spacing: -1px;
  line-height: 1;
  font-weight: 600;

  @include media-query($on-laptop) {
    @include relative-font-size(2.25);
  }
}

.post-content {
  margin-bottom: $spacing-unit;

  h2 {
    @include relative-font-size(2);

    @include media-query($on-laptop) {
      @include relative-font-size(1.75);
    }
  }

  h3 {
    @include relative-font-size(1.625);

    @include media-query($on-laptop) {
      @include relative-font-size(1.375);
    }
  }

  h4 {
    @include relative-font-size(1.25);

    @include media-query($on-laptop) {
      @include relative-font-size(1.125);
    }
  }
}

/**
 * Home
 */

main#home {
  padding-top: 15px;
  width: 100%;
  max-width: 500px;
  margin: 0px auto;

  h1 {
    margin-bottom: 5px;
    font-weight: 500;
  }

  h3 {
    font-weight: 500;
    padding-top: 10px;
    margin-bottom: 5px;
  }
}